import cx from 'classnames'

import { SanityGrid, SanityColor } from '@data/sanity/queries/types/modules'
import { getGridSizeClass, getItemOrderClass } from '@lib/dom'
import {
  getDefaultVerticalPaddingClasses,
  getSpacingClasses,
} from '@lib/spacing'

import GridBlock from './grid-block'

type GridProps = SanityGrid

type ColorClassMap = Record<SanityColor, string | string[]>

const gridColorClasses: ColorClassMap = {
  [SanityColor.WHITE]: 'bg-white text-black',
  [SanityColor.BLACK]: 'bg-black text-white',
}

const Grid = ({
  size,
  spacing,
  columns: rawColumns,
  reverseSequence,
  background,
  noColumnGaps,
  noRowGaps,
}: GridProps) => {
  const columns = rawColumns?.map((column, columnIndex) => {
    if (!column.sizes) {
      return {
        ...column,
        classes: [],
      }
    }

    const columnClasses = column.sizes.map(
      ({ breakpoint, width, justify, align, start }) =>
        getGridSizeClass(breakpoint, width, justify, align, start)
    )
    const orderClasses = column.sizes.map(({ breakpoint, width }) =>
      getItemOrderClass(columnIndex, breakpoint, width, size, reverseSequence)
    )

    return {
      ...column,
      classes: [...columnClasses, ...orderClasses],
    }
  })

  return (
    <section
      className={cx(
        `${background ? gridColorClasses[background] : ''}`,
        getSpacingClasses('m', spacing?.margin)
      )}
    >
      <div
        className={cx(
          'relative container',
          ...getDefaultVerticalPaddingClasses(spacing?.padding),
          getSpacingClasses('p', spacing?.padding, 'right'),
          getSpacingClasses('p', spacing?.padding, 'left')
        )}
      >
        <div className="mx-auto">
          <div
            className={cx(
              `grid grid-cols-${size} gap-x-4 gap-y-8 sm:gap-x-8 lg:gap-x-12 lg:gap-y-12`,
              {
                '!gap-x-0': noColumnGaps,
                '!gap-y-0': noRowGaps,
              }
            )}
          >
            {columns?.map(({ _key, blocks, classes, spacing }) => (
              <div
                key={_key}
                className={cx(
                  classes,
                  getSpacingClasses('m', spacing?.margin),
                  getSpacingClasses('p', spacing?.padding)
                )}
              >
                {blocks?.map((block) => (
                  <GridBlock key={block._key} block={block} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
