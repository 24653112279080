import { type SanityGridSpacingFields } from '@data/sanity/queries/types/modules'

type SpacingValues = SanityGridSpacingFields

type SpacingValueKey = keyof SpacingValues

type Direction = 'top' | 'right' | 'bottom' | 'left'

type ScreenSize = ' ' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const getSpacingValueKey = (direction: Direction, screenSize: ScreenSize) => {
  if (screenSize === ' ') {
    return direction as SpacingValueKey
  }

  const capitalizedDirection = `${direction
    .charAt(0)
    .toUpperCase()}${direction.slice(1)}`

  return `${screenSize}${capitalizedDirection}` as SpacingValueKey
}

const getSpacingDirectionClasses = (
  typeClassPrefix: string,
  direction: Direction,
  spacingValues?: SpacingValues
): Record<string, boolean> => {
  const directionClassSuffix = direction.charAt(0)
  const spacingBaseClass = `${typeClassPrefix}${directionClassSuffix}`

  const defaultValue = spacingValues?.[getSpacingValueKey(direction, ' ')]
  const extraSmallValue = spacingValues?.[getSpacingValueKey(direction, 'xs')]
  const smallValue = spacingValues?.[getSpacingValueKey(direction, 'sm')]
  const mediumValue = spacingValues?.[getSpacingValueKey(direction, 'md')]
  const largeValue = spacingValues?.[getSpacingValueKey(direction, 'lg')]
  const extraLargeValue = spacingValues?.[getSpacingValueKey(direction, 'xl')]

  return {
    [`${spacingBaseClass}-${defaultValue}`]:
      typeof defaultValue !== 'undefined',
    [`xs:${spacingBaseClass}-${extraSmallValue}`]:
      typeof extraSmallValue !== 'undefined',
    [`sm:${spacingBaseClass}-${smallValue}`]: typeof smallValue !== 'undefined',
    [`md:${spacingBaseClass}-${mediumValue}`]:
      typeof mediumValue !== 'undefined',
    [`lg:${spacingBaseClass}-${largeValue}`]: typeof largeValue !== 'undefined',
    [`lx:${spacingBaseClass}-${extraLargeValue}`]:
      typeof extraLargeValue !== 'undefined',
  }
}

export const getSpacingClasses = (
  typeClassPrefix: string,
  spacingValues?: SpacingValues,
  direction?: Direction
): Record<string, boolean> => {
  const topClasses = getSpacingDirectionClasses(
    typeClassPrefix,
    'top',
    spacingValues
  )
  const rightClasses = getSpacingDirectionClasses(
    typeClassPrefix,
    'right',
    spacingValues
  )
  const bottomClasses = getSpacingDirectionClasses(
    typeClassPrefix,
    'bottom',
    spacingValues
  )
  const leftClasses = getSpacingDirectionClasses(
    typeClassPrefix,
    'left',
    spacingValues
  )

  switch (direction) {
    case 'top': {
      return topClasses
    }

    case 'right': {
      return rightClasses
    }

    case 'bottom': {
      return bottomClasses
    }

    case 'left': {
      return leftClasses
    }

    default: {
      return {
        ...topClasses,
        ...rightClasses,
        ...bottomClasses,
        ...leftClasses,
      }
    }
  }
}

export const getDefaultVerticalPaddingClasses = (
  spacingValues?: SpacingValues
) => [
  // Top
  `pt-${spacingValues?.top ?? 12}`,
  `xs:pt-${spacingValues?.xsTop ?? 12}`,
  `sm:pt-${spacingValues?.smTop ?? 16}`,
  `md:pt-${spacingValues?.mdTop ?? 24}`,
  `lg:pt-${spacingValues?.lgTop ?? 32}`,
  `xl:pt-${spacingValues?.xlTop ?? 32}`,

  // Bottom
  `pb-${spacingValues?.bottom ?? 12}`,
  `xs:pb-${spacingValues?.xsBottom ?? 12}`,
  `sm:pb-${spacingValues?.smBottom ?? 16}`,
  `md:pb-${spacingValues?.mdBottom ?? 24}`,
  `lg:pb-${spacingValues?.lgBottom ?? 32}`,
  `xl:pb-${spacingValues?.xlBottom ?? 32}`,
]
